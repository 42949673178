<template>
    <div class="canned-responses">
        <edit
            v-if="showEdit"
            :value="templateEdit"
            :applicability-options="applicabilityOptions"
            :users-options="usersOptions"
            :organizations-options="organizationsOptions"
            :sites-options="sitesOptions"
            :placeholders="placeholders"
            @hide="showEdit = false"
            @succes="templateEdit = null; fetchList()"
        />

        <PageHeaderTabs :tabs="tabs" :current-tab="currentTab" />

        <div class="flex canned-responses__container">
            <card body-class="p-0 pl-0 pr-0 flex flex__column canned-responses__aside-body" class="canned-responses__aside border-radius">
                <div class="pl-6 pt-4 pb-4 pr-4 border-b w-full flex flex__align-center">
                    <button class="icon-btn icon-btn-orange icon-btn-custom cursor-default">
                        <fa :icon="['fal', 'envelope-open-text']"/>
                    </button>

                    <span class="text-header-secondary ml-2">Canned Response Topic</span>
                </div>

                <div class="canned-responses__menu text-body">
                    <div v-for="(item, i) in listCommon" :key="i" class="pl-6 pt-5 pb-5 pr-10 flex flex__justify-between flex__align-center cursor-pointer w-full canned-responses__aside-item" @click="templates = item" :class="{ 'canned-responses__aside-item--active': isActive(item) }" >
                        <div class="f-size-14 lowercase first-letter-uppercase">{{ item.title }}</div>
                        <div class="f-size-12">({{ item.list.length }})</div>
                    </div>

                    <div class="pl-6 pt-6 pb-1 pr-5 flex flex__justify-between flex__align-center w-full">
                        <div class="text-subheader   text-black">Notifications Exceptions</div>
                        <button class="icon-btn icon-btn-small icon-btn-clear" @click="xceptionsListShow = !xceptionsListShow">
                            <fa v-if="xceptionsListShow" :icon="['fal', 'minus']"/>
                            <fa v-else :icon="['fal', 'plus']"/>
                        </button>
                    </div>

                    <div v-if="xceptionsListShow">
                        <div v-for="(item, i) in listXceptions" :key="i" class="pl-12 pt-5 pb-5 pr-10 flex flex__justify-between flex__align-center cursor-pointer w-full canned-responses__aside-item" @click="templates = item" :class="{ 'canned-responses__aside-item--active': isActive(item) }"  >
                            <div class="f-size-14 lowercase first-letter-uppercase">{{ item.title }}</div>
                            <div class="f-size-12">({{ item.list.length }})</div>
                        </div>
                    </div>
                </div>

                <div class="pl-6 pt-3 pb-3 pr-10 border-t flex">
                    <div class="f-size-14 color-brand cursor-pointer" @click="showEdit = true; templateEdit = null;">Add new template +</div>
                </div>
            </card>

            <div class="pl-3 flex-grow flex flex__column">
                <div class="flex justify-end mb-2 mt-4 pb-2">
                    <button class="btn" :disabled="!templateEdit" :class="{ disabled: !templateEdit }" @click="showEdit = true">
                        Edit template
                    </button>

                    <button class="btn background-pink ml-4" :disabled="!templateEdit" :class="{ disabled: !templateEdit }" @click="fetchDelete">
                        Delete
                    </button>
                </div>
                <card class="z-10 border-radius">
                    <div>
                        <strong>
                            Notifications
                            <span v-if="templates && templates.title" class="lowercase">"{{ templates.title }}"</span>
                            <fa v-else class="fa-spin color-green ml-1" :icon="['fad','spinner-third']" />
                        </strong>
                    </div>
                </card>

                <div v-if="!loadingTemplates" class="canned-responses__templates">
                    <canned-response-template
                        v-for="item, i in templatesList"
                        :key="i"
                        :value="item"
                        class="mt-3 cursor-pointer canned-responses__template"
                        :class="{ 'canned-responses__template--active': getSelectedTemplate(item.id) }"
                        @click.native="selectTemplate(item)"
                    />
                </div>

                <div v-else class="flex flex__justify-center pt-5">
                    <fa class="h-5 w-5 text-xl fa-spin color-green" :icon="['fad','spinner-third']" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import CannedResponseTemplate from './Template.vue';
import Edit from './Edit.vue';
import PageHeaderTabs from '~/components/PageHeaderTabs';

export default {
    name: 'CannedResponses',

    data() {
        return {
            placeholders: {},
            list: [],
            xceptionsListShow: true,
            templates: { title: null, list: [] },
            showEdit: false,
            users: [],
            organizations: [],
            sites: [],
            templateEdit: null,
            loadingTemplates: true,
            tabs: {
                0: { value: 'Notifications', routeName: this.$route.name === 'admin.notifications.cannedresponses' ? '/admin/notifications' : '/notifications' },
                1: { value: 'Canned Responses Templates', routeName: this.$route.name === 'admin.notifications.cannedresponses' ? '/admin/notifications/canned-responses' : '/notifications/canned-responses' },
            },
            currentTab: 1,
        }
    },

    computed: {
        user() {
            return this.$store.getters["auth/user"];
        },
        isSuperAdmin() {
            return this.user.level === 'super';
        },
        placeholdersKeysAll() {
            return Object.keys(this.placeholders);
        },

        placeholdersCommon() {
            return this.placeholdersKeysAll.filter(item => { return item.indexOf('XCEPTION') === -1 });
        },

        placeholdersXceptions() {
            return this.placeholdersKeysAll.filter(item => { return item.indexOf('XCEPTION') != -1 });
        },

        applicabilityOptions() {
            return this.placeholdersKeysAll.map(item => {
                return { name: this.commonTitle(item, { exceptionsTilte: true }), value: item };
            });
        },

        usersOptions() {
            return this.users.map(item => ({ name: item.name, value: item.id }));
        },

        organizationsOptions() {
            return this.organizations.map(item => ({ name: item.name, value: item.id }));
        },

        sitesOptions() {
            return this.sites.map(item => ({ name: item.name, value: item.id }));
        },

        listCommon() {
            return this.placeholdersCommon.map(item => {
                return {
                    title: this.commonTitle(item),
                    list: this.list.filter(template => {
                        return template.applicability.find(applicability => {
                            return applicability === item;
                        });
                    }),
                };
            });
        },

        listXceptions() {
            return this.placeholdersXceptions.map(item => {
                return {
                    title: this.xceptionsTitle(item),
                    list: this.list.filter(template => {
                        return template.applicability.find(applicability => {
                            return applicability === item
                        });
                    }),
                };
            });
        },

        templatesList() {
            return this.templates?.list || [];
        },
    },

    methods: {
        xceptionsTitle(value, options = {}) {
            if (!value) {
                return '-' ;
            }

            const result = value.split(':').slice(1).join(' ').replaceAll('_', ' ');

            return `${options.exceptionsTilte ? 'Exceptions: ' : ''}${result[0].toUpperCase()}${result.slice(1).toLowerCase()}`;
        },

        commonTitle(value, options = {}) {
            if (!value) {
                return '-' ;
            }

            if (value.includes(':')) {
                return this.xceptionsTitle(value, options);
            }

            const result = value.split('_').join(' ');

            return `${result[0].toUpperCase()}${result.slice(1).toLowerCase()}`;
        },

        selectTemplate(value) {
            if (this.templateEdit?.id === value.id) {
                this.templateEdit = null;

                return;
            }

            this.templateEdit = value;
        },

        getSelectedTemplate(id) {
            return this.templateEdit?.id === id;
        },

        isActive(value) {
            return value?.title === this.templates?.title;
        },

        async fetchPlaceholders() {
            try {
                const { data: { data } } = await axios.get(this.$apiUrl.cannedResponses.placeholders);

                this.placeholders = data;
            } catch(error) {
                this.placeholders = {};
            }
        },

        async fetchList() {
            this.loadingTemplates = true;

            try {
                const { data: { data } } = await axios.get(this.$apiUrl.cannedResponses.base);

                this.list = data;

                this.templates = [...this.listCommon, ...this.listXceptions].find(item => {
                    return this.templates?.title === item?.title;
                });
            } catch(error) {
                this.list = {};
            } finally {
                this.loadingTemplates = false;
            }
        },

        async fetchUsersList() {
            try {
                const { data: { data } } = await axios.get(this.$apiUrl.users.allowed + `?pageSize=1000`);

                this.users = data;
            } catch(error) {
                this.users = {};
            }
        },

        async fetchOrganizationsList() {
            try {
                const { data: { data } } = await axios.get(this.$apiUrl.organizations.allowed + `?pageSize=1000`);

                this.organizations = data;
            } catch(error) {
                this.organizations = {};
            }
        },

        async fetchSitesList() {
            try {
                const { data: { data } } = await axios.get(this.$apiUrl.sites.allowed + `?pageSize=1000`);

                this.sites = data;
            } catch(error) {
                this.sites = {};
            }
        },

        async fetchDelete() {
            try {
                await axios.delete(this.$apiUrl.cannedResponses.base + `/${this.templateEdit.id}`);

                await this.fetchList();

                this.$snotify.success(`Successfully`);
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            }
        }
    },

    async created() {
        await this.fetchPlaceholders();
        await this.fetchList();

        await Promise.all([
            this.fetchUsersList(),
            this.fetchOrganizationsList(),
            this.fetchSitesList(),
        ]);

        this.templates = this.listCommon[0];
    },

    components: {
        CannedResponseTemplate,
        Edit,
        PageHeaderTabs,
    },
}
</script>

<style lang="scss">
.canned-responses {
    &__container {
        min-height: calc(100vh - 128px);
        max-height: calc(100vh - 128px);
    }

    &__aside {
        max-width: 422px;
        width: 100%;
    }

    &__aside-body {
        height: 100%;
    }

    &__menu {
        flex: 1 1 auto;
        overflow-y: auto;
    }

    &__templates {
        flex: 1 1 auto;
        overflow-y: auto;
    }

    &__template {
        border: 1px solid transparent;
        transition: border .3s;
    }

    &__template--active {
        border: 1px solid var(--brand-color);
    }

    .background-pink {
        background-color:#fa3b73 !important;
        border: 2px solid #fa3b73 !important;
    }

    &__aside-item {
        transition: background .3s;

        &:last-child {
            border: none;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
        }

        &--xception {
            background-color: rgba(0, 0, 0, 0.05);

            &:hover {
                background-color: rgba(0, 0, 0, 0.07);
            }
        }

        &--active {
            background-color: rgba(0, 0, 0, 0.15);

            div:first-child {
                font-weight: 700;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.15);
                div:first-child {
                    font-weight: 700;
                }
            }
        }

        &--xception.canned-responses--active {
            background-color: rgba(0, 0, 0, 0.08) !important;

            &:hover {
                background-color: rgba(0, 0, 0, 0.08) !important;
            }
        }
    }
}
</style>
