<template>
    <div class="flex page-header-tabs border-b w-full color-border mb-4">
        <div class="flex flex__justify-between mr-auto">
            <page-header
                v-for="(tab, index) in tabs"
                :key="`tab_${index}`"
                :class="{ active: currentTab == index }"
                :data-swinger="index"
                :title="tab.value"
                class="cursor-pointer pr-4 text-header-primary"
                :route-name="tab.routeName"
                @click="update(tab)"
            >
                <template v-if="currentTab == index">
                    <template v-for="(_, slot) in $slots">
                        <template :slot="slot">
                            <slot :name="slot"></slot>
                        </template>
                    </template>
                </template>
            </page-header>

            <div v-if="createLabel" class="pb-2">
                <button class="btn btn-sm" @click="$emit('create')">{{ createLabel }}</button>
            </div>
        </div>
        <slot />
    </div>
</template>
<script>

export default {
    props: {
        tabs: {
            // { 'index': { value: string } }
            type: Object,
            default: () => ({}),
        },
        currentTab: {
            type: String,
            default: '',
        },
        createLabel: {
            type: String,
            default: '',
        },
    },
    methods: {
        update(tab) {
            this.$emit('click', tab);
        },
    },
}
</script>
<style lang="scss">
$colorText: rgba(74, 74, 74, var(--text-opacity));

.page-header-tabs {
    a {
        color: rgba(74, 74, 74, var(--text-opacity));

        &:hover {
            color: var(--brand-color);
        }
    }
    .app-page-header {
        margin-bottom: 0 !important;
        border-bottom: 0 !important;
        h2 {
            color: var(--color-text-secondary);

            &:hover {
                color: $colorText;
            }
        }
    }
    .app-page-header.active {
        a {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: -14px;
                width: 100%;
                border-bottom: 2px solid var(--brand-color);
            }
        }
        
        h2 {
            color: $colorText !important;
        }
    }
}
</style>