 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="2xl"
        @hide="$emit('hide')"
        class="canned-response-edit"
        :click-to-close="Boolean(value)"
    >
        <div slot="header">
            <div class="flex flex__align-center flex__justify-between w-full">
                <strong class="f-size-18">
                    <span>{{ value ? 'EDIT' : 'NEW' }}</span>
                    CANNED RESPONSE TEMPLATE
                </strong>
                
                <div class="canned-response-edit__question" @click="showQuestion = true">
                    <svg-importer icon-name="icons/info-question-mark" key="canned-responses-question" class="stroke-brand" />
                </div>
            </div>
        </div>
        <div slot="body">
            <form @submit.prevent="" class="pb-12 pt-3">
                <info v-if="showQuestion" :value="filteredPlaceholders" @hide="showQuestion = false" />

                <modal-field label="Template name" class="mb-6">
                    <input v-model="name" type="text" :class="{ 'is-invalid': submitter && $v.name.$invalid }" />
                </modal-field>

                <modal-field label="Subject" class="mb-6">
                    <input v-model="subject" type="text" :class="{ 'is-invalid': submitter && $v.subject.$invalid }" />
                </modal-field>

                <div class="flex mb-6">
                    <modal-field label="Template applicability" class="w__50-p pr-2">
                        <multiselect v-model="applicability" :options="applicabilityOptions" disabled-select-all multiple track-by="value" label="name" :class="{ 'is-invalid': submitter && $v.applicability.$invalid }" />
                    </modal-field>

                    <modal-field label="Users" class="w__50-p pl-2">
                        <multiselect v-model="users" :options="usersOptions" multiple track-by="value" label="name" :class="{ 'is-invalid': submitter && $v.users.$invalid }" />
                    </modal-field>
                </div>

                <div class="flex mb-6">
                    <modal-field label="Organizations" class="w__50-p pr-2">
                        <multiselect v-model="organizations" :options="organizationsOptions" multiple track-by="value" label="name" />
                    </modal-field>

                    <modal-field label="Sites" class="w__50-p pl-2">
                        <multiselect v-model="sites" :options="sitesOptions" multiple track-by="value" label="name"/>
                    </modal-field>
                </div>

                <modal-field label="Canned message text" class="mb-6">
                    <textarea v-model="text" class="canned-response-edit__text" :class="{ 'is-invalid': submitter && $v.text.$invalid }"></textarea>
                </modal-field>

                <modal-field label="Canned message text abridged (for SMS notification)">
                    <textarea v-model="sms" class="canned-response-edit__sms" :class="{ 'is-invalid': submitter && $v.sms.$invalid }"></textarea>
                </modal-field>
            </form>
        </div>

        <div slot="footer" class="w__100-p border__top-grey-1 pt-6 pl-6 pr-6">
            <div class="flex justify-end w-full">
                <button
                    class="px-8 btn-primary mr-4"
                    :disabled="loading"
                    :class="{ disabled: loading }"
                    @click="fetchSend"
                >
                    Save
                </button>

                <button
                    @click="$emit('hide')"
                    :disabled="loading"
                    :class="{ disabled: loading }"
                    class="px-8 btn-grey-outline"
                >
                    Cancel
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import axios from 'axios';

import { required } from 'vuelidate/lib/validators';

import Info from './Info.vue';

export default {
    name: 'CannedResponseEdit',

    components: {
        Info,
    },

    props: {
        usersOptions: {
            type: Array,
            default: () => [],
        },

        organizationsOptions: {
            type: Array,
            default: () => [],
        },

        sitesOptions: {
            type: Array,
            default: () => [],
        },

        applicabilityOptions: {
            type: Array,
            default: () => { return [] },
        },

        value: {
            type: Object,
            default: () => { return {} },
        },

        placeholders: {
            type: Object,
            default: () => { return {} },
        },
    },

    data() {
        return {
            name: null,
            applicability: null,
            users: null,
            organizations: null,
            sites: null,
            text: null,
            sms: null,
            subject: null,
            submitter: false,
            loading: false,
            showQuestion: false,
        }
    },

    computed: {
        sendValue() {
            return {
                name: this.name,
                subject: this.subject,
                content: this.text,
                content_abridged: this.sms,
                applicability: this.applicability?.map(item => { return item.value }),
                users: this.users?.map(item => { return item.value }),
                organizations: this.organizations?.map(item => { return item.value }),
                sites: this.sites?.map(item => { return item.value }),
            }
        },

        filteredPlaceholders() {
            const applicability = this.applicability?.map(item => { return item.value });

            if (!applicability) {
                return {};
            }

            const result = {};

            for (let key in this.placeholders) {
                const find = applicability.find(item => { return item === key });

                if (!find) {
                    continue;
                }
                
                result[key] = this.placeholders[key];
            }

            return result;
        }
    },

    methods: {
        xceptionsTitle(value, options = {}) {
            if (!value) {
                return '-' ;
            }

            const result = value.split(':').slice(1).join(' ').replaceAll('_', ' ');

            return `${options.exceptionsTilte ? 'Exceptions: ' : ''}${result[0].toUpperCase()}${result.slice(1).toLowerCase()}`;
        },

        commonTitle(value, options) {
            if (!value) {
                return '-' ;
            }

            if (value.includes(':')) {
                return this.xceptionsTitle(value, options);
            }

            const result = value.split('_').join(' ');

            return `${result[0].toUpperCase()}${result.slice(1).toLowerCase()}`;
        },

        initData() {
            if (!this.value) {
                return;
            }

            this.name = this.value.name;
            this.sms = this.value.content_abridged;
            this.text = this.value.content;
            this.subject = this.value.subject;


            this.applicability = this.value.applicability.map(item => {
                return { name: this.commonTitle(item), value: item };
            });

            this.users = this.value.users.map(item => {
                return { name: item.name, value: item.id };
            });

            this.organizations = this.value.organizations.map(item => ({name: item.name, value: item.id }));

            this.sites = this.value.sites.map(item => ({ name: item.name, value: item.id }));
        },

        async fetchSend() {
            this.submitter = true;

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;

            try {
                if (this.value) {
                    await axios.patch(this.$apiUrl.cannedResponses.base + `/${this.value.id}`, this.sendValue);
                } else {
                    await axios.post(this.$apiUrl.cannedResponses.base, this.sendValue);
                }

                this.$snotify.success(`Successfully`);

                this.$emit('succes');
                this.$emit('hide');
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            } finally {
                this.loading = false;
            }
        },
    },

    created() {
        this.initData();
    },

    validations: {
        name: {
            required,
        },
        subject: {
            required,
        },
        applicability: {
            required,
        },
        users: {
            required,
        },
        text: {
            required,
        },
        sms: {
            required,
        },
    },
}
</script>

<style lang="scss">
.canned-response-edit {
    .card-header {
        margin-bottom: 0;
        
        & > div {
            width: 100%;
        }

        .close-modal-btn {
            display: none;
        }
    }
    .modal__footer {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    &__question {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    textarea {
        resize: none;
    }

    &__text {
        min-height: 160px;
    }

    &__sms {
        min-height: 90px;
    }
}
</style>
